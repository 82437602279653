import request from '@/utils/request'
// 提交idfa 和通讯录
function addressBook () {
  const contant = getContactsFromStorage()
  const idfa = localStorage.getItem('idfa') || ''
  if (contant || idfa) {
    const contantList = JSON.stringify(contant) || JSON.stringify([])
    request.post('addressBook', { contantList, idfa: idfa.replace(/"/g, '') })
  }
}

// 接收app传递的通讯录和idfa参数
window.setContant = function (list) {
  localStorage.setItem('contact', JSON.stringify(list))
}
window.setIdfa = (idfa) => {
  localStorage.setItem('idfa', idfa)
}

// 兼容多种通讯录储存到localStorage的key
function getContactsFromStorage () {
  const keyList = [
    {
      contactKey: 'contact',
      name: 'accountName',
      phone: 'accountPhone'
    },
    {
      contactKey: 'jdkan',
      name: 'jdkanxm',
      phone: 'jdkansj'
    },
    {
      contactKey: 'giwef',
      name: 'gerfs',
      phone: 'gehs'
    },
    {
      contactKey: 'qabvzd',
      name: 'eubfs',
      phone: 'eioba'
    },
    {
      contactKey: 'fhasjb',
      name: 'qwed',
      phone: 'wersd'
    },
    {
      contactKey: 'ijksfas',
      name: 'qwjnds',
      phone: 'diwhdf'
    },
    {
      contactKey: 'ifsnsq',
      name: 'iwqond',
      phone: 'wieojf'
    },
    {
      contactKey: 'sdfsasd',
      name: 'sdas',
      phone: 'feww'
    },
    {
      contactKey: 'weweree',
      name: 'werwe',
      phone: 'werer'
    },
    {
      contactKey: 'gerwerr',
      name: 'ererw',
      phone: 'werfd'
    },
    {
      contactKey: 'ioewrn',
      name: 'ewrew',
      phone: 'werwe'
    },
    {
      contactKey: 'fsdfdwe',
      name: 'were',
      phone: 'wrtg'
    },
    {
      contactKey: 'uwjeks',
      name: 'qfsd',
      phone: 'ityr'
    },
    {
      contactKey: 'gbcfy',
      name: 'cbdyh',
      phone: 'ibcgs'
    },
    {
      contactKey: 'ieyrasa',
      name: 'qewe',
      phone: 'fsde'
    },
    {
      contactKey: 'erinds',
      name: 'ewdas',
      phone: 'qwrgf'
    }
  ]
  let contant = ''
  // 使用some方法，当找到有值的key时会自动终止循环
  keyList.some(key => {
    console.log(key.contactKey)
    const valueJSON = localStorage.getItem(key.contactKey)
    if (valueJSON) {
      const valueArray = JSON.parse(valueJSON)
      contant = valueArray.map(item => ({
        accountName: item[key.name],
        accountPhone: item[key.phone]
      }))
      return true
    }
    return false
  })
  return contant
}
export default addressBook
